<template>
    <div>
        <h1>Política de cookies</h1>
        <p>
            En cumplimiento con el deber de información recogido en el apartado
            2º del artículo 22 de la Ley 34/2002, de 11 de Julio, de Servicios
            de la Sociedad de la Información y del Comercio Electrónico, la
            presente política de cookies tiene por finalidad informarle de
            manera clara y precisa sobre las cookies que se utilizan esta página
            web.
        </p>
        <h2>¿Qué son las cookies?</h2>
        <p>
            Una cookie es un chero de pequeño tamaño que los sitios web envían
            al navegador y se descargan en su ordenador. Las cookies permiten
            que la página web almacene y recupere la información sobre su
            visita, como su idioma preferido y otras opciones, con el n de
            mejorar los servicios que se ofrecen y contribuir a tener una mejor
            experiencia de navegación para el usuario.
        </p>
        <h2>Autorización para el uso de cookies</h2>
        <p>
            De conformidad con el banner/aviso de cookies que aparece en el
            sitio web, el usuario podrá aceptar el uso de la totalidad de las
            cookies, rechazarlas, o modificar su configuración. Si decide
            rechazarlas no podrá seguir navegando a través del sitio web. Si
            opta por modificar su configuración, se le indicará que cookies son
            obligatorias para el funcionamiento de la web y cuales Ud. podrá
            rechazar. Si acepta, consiente expresamente el uso de cookies según
            la descripción que a continuación se detalla.
        </p>
        <h2>Tipos de cookies</h2>
        <p>
            En función de quién gestione el dominio desde donde se envían las
            cookies y se traten los datos, se distinguirá entre cookies propias
            y cookies de terceros.
        </p>
        <p>
            Las cookies también pueden clasificarse según el plazo de tiempo que
            permanezcan almacenadas en el navegador del usuario, distinguiéndose
            entre cookies de sesión o cookies persistentes.
        </p>
        <p>
            Finalmente, en función de la finalidad para la que se trate la
            información recopilada, las cookies se pueden clasificar en las
            siguientes categorías:
        </p>
        <ul>
            <li>
                <strong>Cookies técnicas:</strong> permiten al usuario navegar por un sitio web y
                utilizar algunos de los servicios ofrecidos por el mismo (como
                por ejemplo, las que sirven para recordar los elementos
                integrantes de un carrito de compra).
            </li>
            <li>
                <strong>Cookies de personalización:</strong> permiten que el usuario acceda al
                sitio web con unas características determinadas, como puede ser
                el idioma.
            </li>
            <li>
                <strong>Cookies de seguridad:</strong> sirven para impedir o dificultar los
                ataques contra el sitio web.
            </li>
            <li>
                <strong>Cookies de complemento para intercambiar contenidos sociales:</strong>
                los llamados plug-in, que permiten compartir contenidos en redes
                sociales.
            </li>
            <li>
                <strong>Cookies de análisis:</strong> permiten al responsable el seguimiento del
                comportamiento del usuario.
            </li>
            <li>
                <strong>Cookies publicitarias:</strong> permiten gestionar los espacios de
                publicidad del sitio web.
            </li>
            <li>
                <strong>Cookies de publicidad comportamental:</strong> como las anteriores,
                sirven para gestionar los espacios de publicidad del sitio web
                en función de la información recogida sobre el comportamiento
                del usuario y sus hábitos, adaptando la publicidad del usuario.
            </li>
            <li>
                <strong>Cookies de geolocalización:</strong> utilizadas para averiguar el país
                dónde se encuentra el usuario.
            </li>
        </ul>
        <h2>Cookies utilizadas por esta página web</h2>
        <table>
            <thead>
                <tr>
                    <th>Cookie</th>
                    <th>Descripción</th>
                    <th>Duración</th>
                    <th>Tipo</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>laravel_session</td>
                    <td>Identifica la sesión actual del usuario</td>
                    <td>Sesión actual</td>
                    <td>Técnica</td>
                </tr>
                <tr>
                    <td>XSRF-TOKEN</td>
                    <td>Cookie de seguridad para detectar si la petición se realiza desde un origen válido</td>
                    <td>Sesión actual</td>
                    <td>Técnica</td>
                </tr>
                <tr>
                    <td>laravel_eu_cookie_consent</td>
                    <td>Cookie técnica que gestiona la visibilidad del popup de cookies</td>
                    <td>1 año</td>
                    <td>Técnica</td>
                </tr>
                <tr>
                    <td>_ga</td>
                    <td>Google Analytics: distinguir usuarios únicos del sitio web</td>
                    <td>2 años</td>
                    <td>Terceros (Google Analytics)</td>
                </tr>
            </tbody>
        </table>
        <h2>Cómo modificar la configuración de las cookies</h2>
        <p>
            Cómo modificar la configuración de las cookies Usted puede
            restringir, bloquear o borrar las cookies de esta página web,
            utilizando su navegador. En cada navegador la operativa es
            diferente, puede encontrar cómo hacerlo en el menú de ayuda de su
            navegador dónde se establece el procedimiento para su eliminación.
            Para más información:
        </p>
        <ul>
            <li>
                <p>Google Chrome</p>
                <a
                    target="_blank"
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es"
                    >https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a
                >
            </li>
            <li>
                <p>Mozilla Firefox</p>
                <a
                    target="_blank"
                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
                    >https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a
                >
            </li>
            <li>
                <p>Safari</p>
                <a
                    target="_blank"
                    href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                    >https://support.apple.com/es-es/guide/safari/sfri11471/mac</a
                >
            </li>
            <li>
                <p>Internet Explorer</p>
                <a
                    target="_blank"
                    href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie- les-in-internet-explorer"
                    >https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-
                    les-in-internet-explorer</a
                >
            </li>
        </ul>
        <p>
            Es posible que al deshabilitar las cookies la página web no funcione
            correctamente o no pueda acceder a determinadas funciones de la
            misma.
        </p>
        <h2>Modificación de las condiciones</h2>
        <p>
            El titular de esta página web se reserva expresamente el derecho a
            modificar unilateralmente, total o parcialmente, sin necesidad de
            previo aviso, la presente Política de Cookies. El usuario reconoce y
            acepta que es su responsabilidad revisar la presente Política de
            Cookies.
        </p>
    </div>
</template>

<script>
export default {};
</script>

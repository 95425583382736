import Vue from "vue";
import Vuetify, {
    VApp,
    VTextField,
    VSelect,
    VProgressCircular,
    VCheckbox,
    VForm,
    VSnackbar,
    VTextarea
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
    components: {
        VApp,
        VTextField,
        VSelect,
        VCheckbox,
        VForm,
        VProgressCircular,
        VSnackbar,
        VTextarea
    },
    directives: {
        Ripple
    }
});

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#2c697a",
                secondary: "#e3ac4e"
            }
        }
    }
});

export default vuetify;

require("./material");
import Vue from "vue";

import './listeners';
import VueRouter from "vue-router";

import vuetify from "./vuetify";
import routes from "./legal";
import ContactForm from "./components/ContactForm";

Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

import { contactData, contactMethods } from "./contact";
Vue.component('contact-form', ContactForm);

var app = new Vue({
    el: "#app",
    data: () => {
        return {
            ...contactData
        };
    },
    methods: {
        ...contactMethods
    },
    vuetify,
    router
});

<template>
    <div>
        <h1>Política de privacidad</h1>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. informa a los usuarios
            de su página web
            <a href="http://www.solventor.com">www.solventor.com</a> a
            través de esta <strong>Política de Privacidad</strong> sobre el
            tratamiento y protección de los datos de carácter personal de los
            usuarios que puedan ser recabados mediante su navegación o
            contratación de servicios que realicen en este portal. El uso de
            esta web por parte del usuario conlleva la aceptación por su parte
            de esta <strong>Política de Privacidad</strong>.
        </p>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. adopta las medidas
            necesarias para garantizar la seguridad, integridad y
            confidencialidad de los datos conforme a lo establecido en el
            Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
            de abril de 2016, relativo a la protección de las personas físicas
            en lo que respecta al tratamiento de datos personales y a la libre
            circulación de los mismos, y en aquello no previsto por éste por la
            Ley Orgánica 3/2018 de 5 de diciembre, de Protección de Datos
            Personales y garantía de los derechos digitales, el Real Decreto
            1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento
            de desarrollo de la Ley Orgánica de Protección de Datos, y la Ley
            34/2002, de 11 de julio, de Servicios de la Sociedad de la
            Información y de Comercio Electrónico.
        </p>
        <p>
            Mediante los formularios de registro del presente sitio web o el
            envío de correo electrónico se recogen y tratan datos personales de
            cada usuario que son necesarios para la gestión y mantenimiento de
            algunos de los servicios prestados y cuyo tratamiento se rige por la
            presente <strong>Política de Privacidad</strong>.
        </p>
        <h2>Responsable del tratamiento de los datos personales</h2>
        <p>
            El responsable del tratamiento de los datos personales que el
            usuario facilite al acceder y utilizar el sitio web,
            www.solventor.com es ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.,
            con CIF B66849027, con domicilio social a estos efectos en Ravella 12
            Bajos, 08021 Barcelona, España, teléfono 931483731, dirección de correo
            electrónico de contacto <a href="mailto:comanges@cp-aa.es">comanges@cp-aa.es</a>, inscrita en el Registro
            Mercantil de Barcelona Tomo 45886, Folio 119, Hoja B 501384, inscripción 1.
        </p>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. se compromete a aplicar
            las medidas de seguridad informática previstas en la normativa
            citada previamente, con el objetivo de impedir el acceso o uso
            indebido de los datos, su manipulación, deterioro o pérdida.
        </p>
        <h2>Duración del almacenamiento de los datos</h2>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P., como responsable del
            tratamiento, se compromete a almacenar y gestionar los datos
            personales e información recogida mediante esta página web con la
            debida confidencialidad mientras se mantenga la relación profesional
            surgida y la persona interesada no solicite su supresión, y en su
            caso, por un plazo no superior a 5 años, a contar desde la
            finalización de la relación comercial establecida entre el usuario y
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. No obstante, el
            responsable del tratamiento podrá conservar, debidamente bloqueados,
            los datos para atender posibles responsabilidades administrativas o
            jurisdiccionales.
        </p>
        <h2>Finalidades del tratamiento de los datos</h2>
        <p>
            Las operaciones, gestiones y procedimientos técnicos, ya sean
            realizados de forma automatizada como no automatizada, que
            posibiliten la recogida, almacenamiento, modificación, transferencia
            y otras acciones sobre datos de carácter personal, tienen la
            consideración de tratamiento de datos personales.
        </p>
        <p>
            En ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. tratamos los datos
            personales que el usuario nos facilita a través de
            www.solventor.com con el fin de incluirlos en la agenda de
            contactos, gestionar la relación comercial solicitada y utilizar así
            esta información como medio de contacto con el usuario.
        </p>
        <p>Además, el tratamiento de estos datos recabados también implica:</p>
        <ul>
            <li>
                Gestionar el registro y alta de nuestros servicios online y el
                envío de la información que nos sea solicitada, así como
                responder a las consultas o cuestiones que el usuario remita a
                través del sitio web,
            </li>
            <li>
                Previa obtención del consentimiento del usuario, el envío de
                notificaciones y ofertas comerciales de su interés.
            </li>
        </ul>
        <p>
            Los campos de los registros se deben cumplimentar obligatoriamente
            para que ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. pueda cumplir
            con las finalidades mencionadas con anterioridad.
        </p>
        <p>
            El usuario puede oponerse al envío de comunicaciones comerciales en
            cualquier momento mediante el envío de un mensaje de correo
            electrónico a la dirección indicada en esta
            <strong>política de privacidad</strong>.
        </p>
        <h2>Categorías de datos</h2>
        <p>
            Los datos que se recaban se refieren a la categoría de datos
            identificativos, como pueden ser: Nombre y Apellidos, Teléfono,
            Correo electrónico, así como la dirección IP desde donde accede al
            formulario de recogida de datos
        </p>
        <h2>Legitimación para el tratamiento de los datos</h2>
        <p>
            El tratamiento de lo datos personales que proporciona el usuario se
            realiza en base a las siguientes bases jurídicas que legitiman el
            mismo:
        </p>
        <p>
            La contratación de servicios de ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
            y la ejecución del encargo profesional solicitado,
            cuyos términos y condiciones se pondrán a disposición del usuario de
            forma previa a una eventual contratación. Para poder llevar a cabo
            esta relación profesional solicitada, el interesado está obligado a
            facilitar sus datos.
        </p>
        <p>
            El consentimiento libre, específico, informado e inequívoco, ya que
            tras la lectura de la presente
            <strong>Política de Privacidad</strong> el usuario queda informado
            y, en caso de estar conforme, puede aceptarla mediante una
            declaración o una clara acción afirmativa, como el marcado de una
            casilla que se dispone al efecto.
        </p>
        <p>
            En el caso de que el interesado no facilite los citados datos o
            éstos sean erróneos o inexactos, no podremos atender su solicitud,
            resultando imposible proporcionarle la información solicitada o
            llevar a cabo la contratación de los servicios. Por tanto, el
            responsable del tratamiento, ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.,
            quedará exonerado de toda responsabilidad que pudiera
            derivarse de la no ejecución del encargo profesional o de las
            consecuencias que se deriven de estas informaciones inexactas o
            erróneas.
        </p>
        <h2>Comunicación de datos</h2>
        <p>
            Con carácter general ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. no
            comunicará estos datos personales a terceros, con la salvedad de que
            la prestación de un servicio implique la necesidad de una relación
            contractual con un encargado de tratamiento y ello sea estrictamente
            necesario para gestionar y mantener la relación entre el usuario y
            la mencionada empresa, previa autorización expresa por parte del
            usuario. Esto se hará sólo durante el tiempo imprescindible para
            posibilitar la ejecución del contrato de encargo, y en las mismas
            condiciones y con las mismas responsabilidad que se le exigen al
            responsable. Una vez finalizado el encargo, el encargado del
            tratamiento devolverá al Responsable los datos personales y
            suprimirá cualquier copia de la que disponga.
        </p>
        <p>
            Por otra parte, sólo tendrán derecho a acceder a estos datos
            personales los terceros con los que ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
            tenga una obligación legal o contractual de
            facilitarlos, entre los que se incluyen, por ejemplo, el Defensor
            del Pueblo y Jueces y Tribunales interesados en los procedimientos
            relacionados con las reclamaciones presentadas.
        </p>
        <h2>Derechos de los interesados</h2>
        <p>
            El usuario podrá ejercitar en todo momento, en los términos
            establecidos en la legislación vigente, los derechos de acceso,
            rectificación o supresión de datos, solicitar que se limite el
            tratamiento, oponerse al mismo, solicitar la portabilidad de sus
            datos, así como revocar el consentimiento prestado, derechos
            reconocidos en el citado Reglamento (UE). El ejercicio de estos
            derechos puede realizarlo el propio usuario dirigiéndose a ASESORÍA
            POCIELLO COMANGES SANCHO, S.L.P. en la dirección de su sede
            social indicada anteriormente; o a través del envío de un correo
            electrónico a la siguiente dirección <a href="mailto:comanges@cp-aa.es">comanges@cp-aa.es</a>.
        </p>
        <p>
            Para el efectivo ejercicio de estos derechos, el usuario deberá
            acreditar su identidad facilitando su nombre y apellidos, fotocopia
            del DNI o documento identificativo equivalente que acredite su
            identidad, petición en la que se concrete la solicitud, dirección a
            efectos de notificaciones, y fecha y firma del solicitante.
        </p>
        <p>
            Asimismo, el usuario podrá reclamar ante la Agencia Española de
            Protección de Datos (Autoridad de Control competente en esta
            materia), especialmente cuando no haya obtenido satisfacción en el
            ejercicio de sus derechos, mediante escrito dirigido a misma,
            C/Jorge Juan, nº 6, 28001 - Madrid, o a través de la web
            <a href="https://www.agpd.es">https://www.agpd.es</a>.
        </p>
    </div>
</template>

<script>
export default {};
</script>

import axios from "axios";

export const contactData = {
    loading: false,
    valid: false,
    terms: false,
    name: "",
    email: "",
    province: "",
    phone: "",
    message: "",
    nameRules: [v => !!v || "Nombre obligatorio"],
    termsRules: [v => !!v || "Terminos y condiciones de uso obligatorios"],
    emailRules: [
        v => !!v || "Email obligatorio",
        v => /.+@.+\..+/.test(v) || "Email debe ser válido"
    ],
    phoneRules: [
        v => !!v || "Teléfono obligatorio",
        v => /.\d{8}/.test(v) || "Teléfono debe ser válido"
    ],
    messageRules: [
        v => (v && v.length >= 12) || "Mensaje debe tener 12 caracteres mínimo"
    ],
    snackbarText: "",
    snackbar: false,
    color: ""
};

export const contactMethods = {
    submitData(type) {
        var ref_form = null;

        switch(type){
            case 'direct':
            ref_form =this.$refs.form_direct;
            break;
            case 'contact':
            ref_form = this.$refs.form_contact;
            break;
        }

        if (!ref_form.validate() || this.loading) return;
        this.loading = true;
        axios
            .post("/contact", {
                name: this.name,
                email: this.email.trim(),
                phone: this.phone,
                province: this.province,
                terms: this.terms,
                message: this.message,
                type: type
            })
            .then(res => {
                if (res.data.error) {
                    console.log(res.data.error);
                    this.notifyKo(res.data.error);
                    return;
                }
                this.notifyOk("Mensaje enviado con éxito");
            })
            .catch(err => {
                this.notifyKo(err);
            })
            .finally(() => {
                this.loading = false;
                ref_form.resetValidation();
                ref_form.reset();
            });
    },
    notifyOk(message) {
        this.color = "success";
        this.snackbarText = message;
        this.snackbar = true;
    },
    notifyKo(message) {
        this.color = "error";
        this.snackbarText = message;
        this.snackbar = true;
    }
};

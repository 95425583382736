window.addEventListener('DOMContentLoaded', (event) => {
    const mobileMenu = document.querySelector('#mobile-menu');

    document.querySelector('header .hamburger-icon').addEventListener('click', () => {
        mobileMenu.classList.remove('no-transition');
        mobileMenu.querySelector('nav').scrollTo(0, 0);
        document.querySelector('#mobile-menu').classList.add('open');
    });

    document.querySelector('#mobile-menu .close').addEventListener('click', () => {
        mobileMenu.classList.remove('open');
    });
});


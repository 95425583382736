import LegalContainer from "./components/LegalContainer.vue";
import Legal from "./components/Legal.vue";
import Privacy from "./components/Privacy.vue";
import Cookies from "./components/Cookies.vue";
const routes = [
    {
        path: "/legal",
        component: LegalContainer,
        children: [
            {
                path: "aviso-legal",
                component: Legal,
                name: "legal",
                meta: {
                    title: "Aviso legal"
                }
            },
            {
                path: "privacidad",
                component: Privacy,
                name: "privacy",
                meta: {
                    title: "Política de privacidad"
                }
            },
            {
                path: "cookies",
                component: Cookies,
                name: "cookies",
                meta: {
                    title: "Política de cookies"
                }
            },
            {
                path: "*",
                redirect: { name: "legal" }
            }
        ]
    }
];

for (let route of routes[0].children) {
    if (route.path) {
        continue;
    }
    const path = route.paths[window.appLocale];
    if (!path) {
        throw new Error("Route not found");
    }
    route.path = path;
}

export default routes;

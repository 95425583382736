
<script>
import { contactData, contactMethods } from "../../js/contact";

export default {
    data: () => {
        return {
            ...contactData
        };
    },
    methods: {
        ...contactMethods
    },
}
</script>

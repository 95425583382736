<template>
    <div>
        <h1>Aviso legal</h1>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P, responsable de este
            sitio web, pone a disposición de los usuarios de Internet el
            presente documento, cuya finalidad es cumplir las obligaciones
            establecidas en la Ley 34/2002, de 11 de junio, de Servicios de la
            Sociedad de la Información y de Comercio Electrónico (LSSICE) y
            demás normativa aplicable, así como proporcionar información sobre
            las condiciones de uso.
        </p>
        <h2>Datos identificativos</h2>
        <p>
            En cumplimiento del deber de información regulado en el art. 10
            LSSICE, a continuación, se facilitan los datos necesarios del
            titular de la página web:
        </p>
        <p>
            La empresa que gestiona los servicios y el dominio web
            <a href="http://www.solventor.es">www.solventor.es</a> es
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P., con CIF B66849027, con
            domicilio social a estos efectos en Ravella 12 Bajos, 08021 -
            Barcelona, España, teléfono 931483731, dirección de correo electrónico
            de contacto <a href="mailto:comanges@cp-aa.es">comanges@cp-aa.es</a>, inscrita en el
            Registro Mercantil de Barcelona Tomo 45886, Folio 119, Hoja B 501384, inscripción 1.
        </p>
        <h2>Usuarios</h2>
        <p>
            Toda persona, ya sea física o jurídica, que acceda, navegue, utilice
            o participe en los servicios y actividades, gratuitas u onerosas,
            desarrolladas mediante esta página web asume la condición de
            USUARIO, y como tal, a través de dicho acceso, se compromete a la
            observancia y riguroso cumplimiento de las disposiciones aquí
            presentes, así como a cualquier otra disposición legal que resulte
            de aplicación, obligándose así a hacer un uso correcto del sitio
            web. El usuario responderá frente a ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
            o frente a terceros de cualesquiera daños y perjuicios
            que pudieran causarse como consecuencia del incumplimiento de dicha
            obligación.
        </p>
        <h2>Modificaciones</h2>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. se reserva el derecho
            de realizar unilateralmente las modificaciones que estime
            pertinentes en su portal, pudiendo cambiar, suprimir o añadir tanto
            los contenidos como los servicios que se presten a través del mismo,
            sin que exista obligación de preavisar o poner en conocimiento de
            los usuarios dichas modificaciones, entendiéndose como suficiente
            con la publicación en el sitio web del prestador.
        </p>
        <p>
            La duración de la prestación del servicio del portal se limita al
            momento en que el usuario esté conectado al sitio web
            <a href="http://www.solventor.es">www.solventor.es</a> o a
            alguno de los servicios que éste proporciona. Por ello, el usuario,
            en cada ocasión que pretenda utilizar el portal, debe leer
            detenidamente este Aviso Legal, ya que el mismo y sus respectivas
            condiciones de uso pueden verse alteradas en cualquier momento. Por
            tanto, la vigencia de las citadas condiciones variará en función de
            su exposición y se mantendrá mientras estén debidamente publicadas,
            hasta que se vean sustituidas por otras.
        </p>
        <h2>Condiciones de uso del sitio web</h2>
        <p>
            Las presentes condiciones de uso y navegación tienen como finalidad
            regular la relación entre el titular de la página web, como
            prestador del servicio, y los usuarios que acceden, navegan y
            disfrutan del servicio ofrecido.
        </p>
        <p>
            <a href="http://www.solventor.es">www.solventor.es</a>
            proporciona el acceso libre y gratuito a gran cantidad de
            información, servicios y datos (en adelante, “los contenidos”),
            también facilita el acceso a contenidos de pago cuya propiedad
            pertenece a ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. o a sus
            licenciantes.
        </p>
        <p>
            El usuario asume la responsabilidad del uso adecuado del portal de
            conformidad con la Ley y las presentes condiciones, responsabilidad
            que se extiende al registro necesario para acceder a determinados
            servicios y contenidos que se prestan. Dicho registro conlleva la
            cumplimentación del correspondiente formulario, en el cual el
            usuario garantiza la autenticidad y actualidad de todos aquellos
            datos que comunique y se compromete a no proporcionar información o
            documentación falsa, fraudulenta o ilícita. El usuario será el único
            responsable de las manifestaciones falsas o inexactas que realice y
            de los perjuicios que cause a ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
            o a terceros por la información que facilite.
        </p>
        <p>
            El usuario se compromete expresamente a hacer un adecuado uso de los
            contenidos y servicios [por ejemplo, servicios de chat, foros de
            discusión, grupos de noticias...] que
            <a href="http://www.solventor.es">www.solventor.es</a> ofrece,
            conforme a lo establecido en la ley, la moral, el orden público y
            las presentes condiciones y, con carácter enunciativo pero no
            limitativo, a no emplearlos para:
        </p>
        <ul>
            <li>
                Difundir contenidos delictivos, violentos, pornográficos,
                racistas, xenófobos, ofensivos, de apología al terrorismo, que
                atenten contra los derecho humanos o, en general, contrarios a
                la ley o al orden público.
            </li>
            <li>
                Introducir intencionadamente en la red virus informáticos o
                realizar actuaciones susceptibles de alterar, estropear,
                interrumpir o generar errores o daños en los sistemas físicos y
                lógicos de ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.,
                creadora del sitio web, o de terceras personas; así como
                obstaculizar el acceso de otros usuarios al sitio web y a sus
                servicios mediante el consumo masivo de los recursos
                informáticos a través de los cuales ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
                presta sus servicios.
            </li>
            <li>
                Intentar acceder a las cuentas de correo electrónico de otros
                usuarios o a áreas restringidas de los sistemas informáticos de
                ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. o de terceros y, en
                su caso, extraer información.
            </li>
            <li>
                Vulnerar los derechos de propiedad intelectual o industrial, así
                como difundir, transmitir o poner a disposición de terceros
                cualquier tipo de información, elemento o contenido que suponga
                una violación del secreto de las comunicaciones y la legislación
                sobre protección de datos de carácter personal.
            </li>
            <li>
                Suplantar la identidad de otro usuario, de las administraciones
                públicas o de un tercero, utilizando sus claves de registro a
                los distintos servicios y/o contenidos del sitio web.
            </li>
            <li>
                Reproducir, copiar, distribuir, poner a disposición o de
                cualquier otra forma comunicar públicamente, transformar o
                modificar los contenidos, a menos que se cuente con la
                autorización del titular de los correspondientes derechos de
                explotación o ello resulte legalmente permitido.
            </li>
            <li>
                Recabar datos con finalidad publicitaria y remitir publicidad de
                cualquier clase y comunicaciones con fines de venta u otra de
                naturaleza comercial sin que medie previa solicitud o
                consentimiento.
            </li>
        </ul>
        <p>
            Cualquier incumplimiento de las cláusulas contenidas en el presente
            sitio web Aviso Legal, así como otros contenidos que supongan
            obligaciones para el usuario y en general de la legalidad vigente en
            España, se comunicará inmediatamente por parte de ASESORÍA POCIELLO COMANGES SANCHO,
            S.L.P. a las autoridades pertinentes,
            comprometiéndose ésta a cooperar con las mismas. En tal caso, el
            usuario responderá frente a ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
            S.L o frente a terceros, de cualesquiera daños y perjuicios que
            pudieran causarse como consecuencia del incumplimiento de estas
            obligaciones.
        </p>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. no garantiza que su
            página web cumpla, total o parcialmente, con las legislaciones de
            otros países. Por tanto, si el usuario reside o se encuentra
            domiciliado en cualquier otro lugar que no sea España, y decide
            acceder y/o navegar en el presente sitio web, lo hará bajo su propia
            responsabilidad y riesgo, debiendo asegurarse de que tal acceso y/o
            navegación cumple con la legislación local aplicable en su caso.
        </p>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. no se hace responsable
            de ningún daño o pérdida que se derive de un ataque de denegación de
            servicio, virus o cualquier otro programa o material
            tecnológicamente perjudicial que pueda afectar a su ordenador,
            equipo informático, datos o materiales como consecuencia del uso de
            esta página web o de la descarga de contenidos de la misma o a los
            que la misma re direccione.
        </p>
        <h2>Protección de datos de carácter personal</h2>
        <p>
            El titular de la página web se compromete a tratar los datos
            personales del usuario de conformidad con lo establecido en la
            legislación vigente en la materia. Concretamente, se compromete a
            aplicar lo dispuesto en la LO 3/2018, de 5 de diciembre, de
            Protección de Datos de Carácter Personal y garantía de los derechos
            digitales, y en el Reglamento (UE) 2016/679 del Parlamento Europeo y
            del Consejo de 27 de abril de 2016.
        </p>
        <p>
            Para más información sobre el tratamiento de sus datos personales en
            el portal, diríjase a la
            <a @click.prevent="activeRoute('privacidad')"
                >Política de Privacidad</a
            >.
        </p>
        <h2>Propiedad intelectual e industrial</h2>
        <p>
            Todos los contenidos del sitio web, entre otros, textos,
            fotografías, gráficos, imágenes, iconos, tecnología, software, links
            y demás contenidos audiovisuales, así como su diseño gráfico y
            códigos fuente, constituyen una obra cuya propiedad pertenece a
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. sin que puedan
            entenderse cedidos al usuario ninguno de los derechos de explotación
            sobre los mismos más allá de lo estrictamente necesario para el
            correcto uso de la web, y con independencia de que sean susceptibles
            o no de propiedad intelectual.
        </p>
        <p>
            Asimismo, todas las marcas, nombres comerciales o signos distintivos
            de cualquier clase que aparecen en el sitio web son propiedad de
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P., sin que pueda
            entenderse que el uso o acceso al mismo atribuya al usuario derecho
            alguno sobre los mismos.
        </p>
        <p>
            Queda prohibida la reproducción total o parcial, explotación,
            distribución, modificación, cesión o comunicación pública de los
            contenidos y cualquier otro acto que no haya sido expresamente
            autorizado por el titular de los derechos de explotación. Cualquier
            uso no autorizado previamente se considera un incumplimiento grave
            de los derechos de propiedad intelectual o industrial del autor.
        </p>
        <p>
            Para realizar cualquier tipo de observación respecto a posibles
            incumplimientos de los derechos de propiedad intelectual o
            industrial, así como sobre cualquiera de los contenidos del sitio
            web, el usuario deberá notificar dicha circunstancia a ASESORÍA POCIELLO COMANGES SANCHO,
            S.L.P. adjuntando la información pertinente.
        </p>
        <p>
            En cualquier caso, ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. no
            asume responsabilidad alguna respecto de los derechos de propiedad
            intelectual o industrial titularidad de terceros que se vean
            infringidos por un tercero o por el usuario.
        </p>
        <h2>Enlaces</h2>
        <p>
            El sitio web de ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. puede
            contener enlaces o hipervínculos a otros sitios de Internet,
            respecto a los cuales no ejerce ningún tipo de control. Por tanto,
            una vez que el usuario acceda a los enlaces de terceros y abandone
            el sitio web, tanto el presente Aviso Legal, como la Política de
            Privacidad y la Política de Cookies dejarán de surtir efecto, ya que
            los sitios web a los que el usuario acceda están sujetos a sus
            propias políticas.
        </p>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. no puede asumir
            responsabilidad alguna por el contenido que pueda aparecer en
            páginas de terceros, ni garantiza la disponibilidad técnica,
            calidad, fiabilidad, exactitud, amplitud, veracidad, validez y
            constitucionalidad de cualquier material o información contenida en
            ninguno de dichos enlaces o hipervínculos. Asimismo, la inclusión de
            estas conexiones externas a sitios web ajenos no implica ningún tipo
            de asociación, fusión o participación con las entidades conectadas.
        </p>
        <h2>Exclusión de garantías y responsabilidad</h2>
        <p>
            La información suministrada por del presente sitio web es de
            carácter general y tiene una finalidad meramente informativa, sin
            que se garantice plenamente el acceso a todos los contenidos, ni su
            exactitud, exhaustividad, corrección, vigencia o actualidad, ni su
            idoneidad o utilidad para un objetivo específico.
        </p>
        <p>
            ASESORÍA POCIELLO COMANGES SANCHO, S.L.P. no se hace responsable,
            en ningún caso, de los daños y perjuicios de cualquier naturaleza
            derivados de, a título enunciativo:
        </p>
        <ul>
            <li>
                Errores u omisiones en los contenidos. ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
                no garantiza que los contenidos vayan a
                estar actualizados permanentemente, ni que carezcan de cualquier
                tipo de error.
            </li>
            <li>
                Ausencia de disponibilidad del portal. ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.
                no se hace responsable de los posibles
                daños o perjuicios generados en el usuario como consecuencia de
                fallos o desconexiones en las redes de telecomunicaciones que
                supongan la suspensión, cancelación o interrupción del servicio
                del sitio web, ya que el funcionamiento de estas redes depende
                de terceros.
            </li>
            <li>
                Presencia de virus o programas maliciosos o lesivos en los
                contenidos que puedan alterar los sistemas informáticos,
                documentos electrónicos o datos de los usuarios, a pesar de
                haber adoptado todas las medidas tecnológicas necesarias para
                prevenirlo. Corresponde al usuario, en todo caso, estar provisto
                de herramientas adecuadas que le protejan ante programas
                informáticos dañinos.
            </li>
        </ul>
        <h2>Ley aplicable y jurisdicción</h2>
        <p>
            Las relaciones establecidas entre ASESORÍA POCIELLO COMANGES SANCHO, S.L.P.,
            titular de la página web, y el usuario se regirá por la
            normativa española vigente y la resolución de cualquier posible
            controversia que pueda surgir quedará sometida a los Juzgados y
            tribunales de la ciudad de Barcelona.
        </p>
    </div>
</template>

<script>
export default {
    props: {
        activeRoute: {
            type: Function,
            required: true
        }
    }
};
</script>

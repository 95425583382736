import { render, staticRenderFns } from "./LegalContainer.vue?vue&type=template&id=fdc8195c&scoped=true&"
import script from "./LegalContainer.vue?vue&type=script&lang=js&"
export * from "./LegalContainer.vue?vue&type=script&lang=js&"
import style0 from "./LegalContainer.vue?vue&type=style&index=0&id=fdc8195c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc8195c",
  null
  
)

export default component.exports
<template>
    <div class="legal-component">
        <div class="select relative" @click="toggleSelect">
            <span></span>
            <span>{{ $route.meta.title }}</span>
            <i
                class="material-icons mdc-button__icon"
                :class="{ open: selectIsOpen }"
                aria-hidden="true"
                >keyboard_arrow_down</i
            >
            <div
                v-show="selectIsOpen"
                class="options bg-white absolute text-left font-light"
            >
                <div
                    v-for="(route, index) in routes"
                    :key="index"
                    class="option pl-5"
                    :class="{ selected: isActive(route.path) }"
                    @click="activeRoute(route.path)"
                >
                    {{ route.meta.title }}
                </div>
            </div>
        </div>

        <div class="display-flex menu-fixed">
            <div class="menu-container">
                <ul class="fixed-menu">
                    <li
                        v-for="(route, index) in routes"
                        :key="index"
                        class="menu-item"
                        :class="{ active: isActive(route.path) }"
                        @click="activeRoute(route.path)"
                    >
                        {{ route.meta.title }}
                    </li>
                </ul>
            </div>
            <transition name="fade" appear mode="out-in">
                <router-view class="router-content" :active-route="activeRoute"></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentRoute: null,
            selectIsOpen: false,
            routes: null,
        };
    },

    methods: {
        isActive(route) {
            return this.currentRoute === route;
        },
        toggleSelect() {
            this.selectIsOpen = !this.selectIsOpen;
        },
        activeRoute(route) {
            const path = `/legal/${route}`;
            if (this.$route.path !== path) {
                this.$router.push({ path });
                this.currentRoute = route;
            }
        },
    },
    mounted() {
        var wordspath = this.$route.path.split("/")
        this.currentRoute = wordspath[2];
    },
    watch: {
        selectIsOpen(open) {
            const hideSelect = e => {
                if (!e.target.closest(".select")) {
                    this.selectIsOpen = false;
                }
            };
            if (open) {
                document.body.addEventListener("click", hideSelect);
            } else {
                document.body.removeEventListener("click", hideSelect);
            }
        },
        $route: {
            handler(route) {
                document.title = route.meta.title;
            },
            immediate: true,
        }
    },
    created() {
        import('../legal').then(legal => {
            this.routes = legal.default[0].children.slice(0, -1);
        });
    }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
ul {
    list-style-type: none;
}
.display-flex {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
    .menu-container {
        display: none;

        @media (min-width: 1024px) {
            display: flex;
            flex-direction: column;
            position: -webkit-sticky; /* Safari */
            position: sticky;
            align-self: flex-start;
        }
    }
}
.menu-fixed{
    ul{
        padding: 0;
    }
}
.router-content {
    flex-grow: 1;
    margin-top: 2.5rem;
    @media (min-width: 1024px) {
        margin-left: 5rem;
        margin-top: 0rem;
    }
}
</style>
